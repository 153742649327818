import React from "react";
import ExpensesItem from "./ExpensesItem";
import "./ExpensesList.css";

const ExpensesList = (props) => {
  let expensesContent = <p>No expenses found.</p>;

  if (props.items.length === 0) {
    return <h2 className="expenses-list__fallback">Found no expenses</h2>
  }

    return (
      <ul className="expenses-list">
        {
          (expensesContent = props.items.map((expense) => (
            <ExpensesItem
              key={expense.id}
              title={expense.title}
              amount={expense.amount}
              date={expense.date}
            />
          )))
        }
      </ul>
    );
};

export default ExpensesList;
